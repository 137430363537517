<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-4 col-12">List Hosts</div>
    <div class="col-lg-8 col-12 text-end d-flex justify-content-end">
      <div class="input-group input-group-sm custom-form-group mb-0 me-2" v-bind:style="[
        searchnamebtnactive ? { width: '328px' } : { width: '275px' },
      ]">
        <input type="text" v-model="searchfullname" class="form-control text-capitalize"
          placeholder="Search By First Or Last Name" aria-label="Search" aria-describedby="basic-addon1"
          autocomplete="off" maxlength="30" @keypress="isLetterWithSpace($event)" @keyup.enter="searchFnameGuest(searchfullname)" :disabled="hidesearchname"
          style="padding: 6px 15px" />
        <button type="button" class="header-btn-outer btn btn-primary" :disabled="searchfullname.length == ''"
          @click="searchFnameGuest(searchfullname)" :style="{ width: '50px' }">
          <span v-if="!searchnameloader"><i class="pi pi-search" aria-hidden="true"></i></span>
          <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="searchnameloader">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
        <button type="button" class="header-btn-outer btn btn-primary ms-1" @click="searchNameGuestStop()"
          :style="{ width: '50px' }" v-show="searchnamebtnactive">
          <i class="pi pi-times" aria-hidden="true"></i>
        </button>
      </div>
      <!-- <div class="input-group input-group-sm custom-form-group mb-0" v-bind:style="[
        searchmobbtnactive ? { width: '275px' } : { width: '220px' },
      ]">
        <input type="text" v-model="searchmobile" class="form-control" id="searchmobInput"
          placeholder="Search By Mobile No." :disabled="hidesearchmob" aria-label="Search"
          aria-describedby="basic-addon1" autocomplete="off" maxlength="10" @keypress="mobileSearchFormat"
          style="padding: 6px 15px" />
        <button type="button" class="header-btn-outer btn btn-primary" :disabled="searchmobile.length <= 3"
          @click="searchMobGuest(searchmobile)" :style="{ width: '50px' }">
          <span v-if="!searchmobloader"><i class="pi pi-search" aria-hidden="true"></i></span>
          <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="searchmobloader">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
        <button type="button" class="header-btn-outer btn btn-primary ms-1" @click="searchMobGuestStop()"
          :style="{ width: '50px' }" v-show="searchmobbtnactive">
          <i class="pi pi-times" aria-hidden="true"></i>
        </button>
      </div> -->
      <button type="button" @click="guestFilterModalOpen()" class="header-filter-btn-outer btn btn-light"
        title="Filter">
        <span class="custom-filter-apply-alert"></span>
        <i class="pi pi-filter-fill custom-text-primary" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="approved-host-tab" data-bs-toggle="tab" data-bs-target="#approvedHost"
        type="button" role="tab" aria-controls="approved-host" aria-selected="true">
        Approved Hosts
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="unapproved-host-tab" data-bs-toggle="tab" data-bs-target="#unapprovedHost"
        type="button" role="tab" aria-controls="unapproved-host" aria-selected="false"
        @click="redirectInactiveHostUrl()">
        Unapproved Hosts
      </button>
    </li>
  </ul>
  <div class="tab-content mt-2" id="myTabContent">
    <div class="tab-pane fade show active" id="approvedHost" role="tabpanel" aria-labelledby="approved-host-tab">
      <div class="company-section-outer">
        <div class="layout-content-section">
          <div class="custom-ultima-datatable" style="height: calc(100vh - 188px)">
            <DataTable :value="hostList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex" :paginator="true"
              :rows="30" :totalRecords="totalRecords" @page="changePage($event)"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              :lazy="true" dataKey="aa1" v-model:selection="selectedHost" :loading="loading">
              <template #empty>No records found.</template>
              <template #loading>Loading records. Please wait...</template>
              <Column headerStyle="width: 3%" bodyStyle="width: 3%" class="justify-content-center"
                selectionMode="multiple"></Column>
              <Column header="Select All" headerStyle="width: 96%" bodyStyle="width: 96%">
                <template #body="{ data }">
                  <div class="company-detail-box-outer mb-3">
                    <div class="row">
                      <div class="col-lg-2 col-md-2 col-12 user-name-table-col">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <div>
                                <img v-if="(data.ac17 == null || data.ac17 == '') &&
        (data.ac18 == null || data.ac18 == '')
        " src="/assets/images/profile.svg" class="rounded bg-light me-auto d-block" alt=""
                                  style="width: 72px; height: 72px" />
                                <img v-if="data.ac17 != null &&
        data.ac17 != '' &&
        (data.ac18 == null || data.ac18 == '')
        " :src="'https://storage.googleapis.com/' +
        this.hoststorageimgpath +
        '/visitor/aadharphoto/' +
        data.ac17
        " class="rounded bg-light me-auto d-block voter-photo-img" alt=""
                                  style="width: 72px; height: 72px" />
                                <img v-if="data.ac18 != null && data.ac18 != ''" :src="'https://storage.googleapis.com/' +
        this.hoststorageimgpath +
        '/visitor/profile/' +
        data.ac18
        " class="rounded bg-light me-auto d-block voter-photo-img" alt=""
                                  style="width: 72px; height: 72px" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12 user-name-table-col">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Host Name</label>
                              <div class="from-label-value text-capitalize">
                                {{ data.ac3 ? data.ac3 : "N/A" }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Legal Name</label>
                              <div class="from-label-value text-capitalize">
                                {{ data.ac28 ? data.ac28 : "N/A" }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12 user-name-table-col">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label"><span>City </span> | <span>Area</span></label>
                              <div class="from-label-value text-capitalize">
                                <span>
                                  {{ data.ac13 ? data.ac13 : "N/A" }}</span>
                                |
                                <span>{{ data.ac15 ? data.ac15 : "N/A" }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label"><span>Registration</span> |
                                <span>Approval Date</span></label>
                              <div class="from-label-value text-capitalize">
                                <span>
                                  {{
        format_date(data.z501 ? data.z501 : "N/A")
      }}</span>
                                |
                                <span>{{
          format_date(data.ac37 ? data.ac37 : "N/A")
        }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12 user-name-table-col">
                        <div class="d-flex justify-content-between">
                          <div>
                            <div class="customer-label-group mb-2">
                              <label class="form-label"><span>Contact Name </span> |
                                <span>Designation</span></label>
                              <div class="from-label-value text-capitalize">
                                <span> {{ data.ac6 ? data.ac6 : "N/A" }}</span>
                                |
                                <span>{{ data.ac9 ? data.ac9 : "N/A" }}</span>
                              </div>
                            </div>
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Mobile</label>
                              <div class="from-label-value text-capitalize">
                                <!-- <span>{{ maskedNumber(data.ac7 ? data.ac7 : "N/A") }}</span> -->
                              </div>
                            </div>
                          </div>
                          <div class="">
                            <button class="btn custom-outline-call-btn me-2" title="Request Call contact person">
                              <i class="pi pi-phone call-btn-color"></i>
                            </button>
                            <button type="button" title="View Details" class="btn custom-outline-view-btn me-2"
                              @click="hostdetailModalOpen(data)">
                              <i class="pi pi-eye call-btn-color"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane fade" id="unapprovedHost" role="tabpanel" aria-labelledby="unapproved-host-tab">
      In Active Host
    </div>
  </div>
  <!-- Start view host details modal open -->
  <div class="modal-mask" v-if="isHostDetailsModal">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header custom-custmer-header-border">
          <h5 class="modal-title" id="hostDetails">Host Details</h5>
          <button type="button" class="btn-close" @click="isHostDetailsModal = false"></button>
        </div>
        <ul class="nav nav-tabs custom-customer-modal-tab" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="host-company-tab" data-bs-toggle="tab" data-bs-target="#hostdetails"
              type="button" role="tab" aria-controls="host-details" aria-selected="true">
              Company Details
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="host-userlist-tab" data-bs-toggle="tab" data-bs-target="#viewHostUserList"
              type="button" role="tab" aria-controls="user-list" aria-selected="false" @click="getUserListByHostId()">
              User List
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="host-branchlist-tab" data-bs-toggle="tab" data-bs-target="#viewBranchDetails"
              type="button" role="tab" aria-controls="branch-list" aria-selected="false"
              @click="getHostBranchesByHostId()">
              Branch List
            </button>
          </li>
        </ul>
        <div class="modal-body modal-body-scroll-outer">
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="hostdetails" role="tabpanel" aria-labelledby="host-company-tab">
              <div class="modal-scroll-min-height">
                <div v-if="!showLoaderHostDetailsModal">
                  <div class="row" v-if="isShowHostDetails != null">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="voter-exit-box-outer mb-3">
                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="row">
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Host Name</label>
                                  <div class="from-label-value text-capitalize">
                                    {{ hostData.ac3 ? hostData.ac3 : "N/A" }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Legal Name</label>
                                  <div class="from-label-value text-capitalize">
                                    {{ hostData.ac28 ? hostData.ac28 : "N/A" }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Company Type</label>
                                  <div class="from-label-value text-capitalize">
                                    {{ hostData.ac23 ? hostData.ac23 : "N/A" }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Pan No</label>
                                  <div class="from-label-value text-capitalize">
                                    {{ hostData.ac5 ? hostData.ac5 : "N/A" }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">GST No</label>
                                  <div class="from-label-value text-capitalize">
                                    {{ hostData.ac4 ? hostData.ac4 : "N/A" }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Host Status</label>
                                  <div class="from-label-value text-capitalize status-active" v-if="hostData.ac32 == 1">
                                    verified
                                  </div>
                                  <div class="from-label-value text-capitalize status-active" v-if="hostData.ac32 == 2">
                                    not verified
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="row">
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">State<span
                                      class="mx-1 vertical-pipe-color">|</span>City<span
                                      class="mx-1 vertical-pipe-color">|</span>Area</label>
                                  <div class="from-label-value text-capitalize">
                                    <span>
                                      {{
        hostData.ac11 ? hostData.ac11 : "N/A"
      }}</span>
                                    |
                                    <span>
                                      {{
          hostData.ac13 ? hostData.ac13 : "N/A"
        }}</span>
                                    |
                                    <span>
                                      {{
          hostData.ac15 ? hostData.ac15 : "N/A"
        }}</span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Address</label>
                                  <div class="from-label-value text-capitalize">
                                    {{ hostData.ac21 ? hostData.ac21 : "N/A" }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Pincode</label>
                                  <div class="from-label-value text-capitalize">
                                    {{ hostData.ac16 ? hostData.ac16 : "N/A" }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Contact Person Details</label>
                                  <div class="from-label-value text-capitalize">
                                    <span>{{
        hostData.ac6 ? hostData.ac6 : "N/A"
      }}</span>
                                    |
                                    <span>{{
          hostData.ac7 ? hostData.ac7 : "N/A"
        }}</span>
                                    |
                                    <span>{{
          hostData.ac9 ? hostData.ac9 : "N/A"
        }}</span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Approval Date</label>
                                  <div class="from-label-value text-capitalize">
                                    {{
          format_timestamp(
            hostData.ac37 ? hostData.ac37 : "N/A"
          )
        }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="customer-label-group">
                        <label class="form-label">No records found.</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="showLoaderHostDetailsModal">
                  <div class="custom-modal-spinner-loader">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="viewBranchDetails" role="tabpanel" aria-labelledby="host-branchlist-tab">
              <div class="modal-scroll-min-height">
                <div v-if="!isBranchlistModalOpen">
                  <div class="row" v-if="branchList && branchList.length > 0">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="voter-exit-box-outer mb-3" v-for="(branch, index) of branchList" :key="index">
                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="row">
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Host Name</label>
                                  <div class="from-label-value text-capitalize">
                                    {{ branch.ae3 ? branch.ae3 : "N/A" }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Branch Category</label>
                                  <div class="from-label-value text-capitalize">
                                    {{ branch.ae14 ? branch.ae14 : "N/A" }}
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">City</label>
                                  <div class="from-label-value text-capitalize">
                                    {{ branch.ae7 ? branch.ae7 : "N/A" }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Address</label>
                                  <div class="from-label-value text-capitalize">
                                    {{ branch.ae11 ? branch.ae11 : "N/A" }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">MapLink</label>
                                  <div class="from-label-value text-capitalize">
                                    {{ branch.ae12 ? branch.ae12 : "N/A" }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="row">
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Branch Name</label>
                                  <div class="from-label-value text-capitalize">
                                    {{ branch.ae5 ? branch.ae5 : "N/A" }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">State</label>
                                  <div class="from-label-value text-capitalize">
                                    {{ branch.ae17 ? branch.ae17 : "N/A" }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Area</label>
                                  <div class="from-label-value text-capitalize">
                                    {{ branch.ae9 ? branch.ae9 : "N/A" }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Pincode</label>
                                  <div class="from-label-value text-capitalize">
                                    {{ branch.ae10 ? branch.ae10 : "N/A" }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="customer-label-group">
                        <label class="form-label">No records found.</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="isBranchlistModalOpen">
                  <div class="custom-modal-spinner-loader">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="viewHostUserList" role="tabpanel" aria-labelledby="host-userlist-tab">
              <div class="modal-scroll-min-height">
                <div v-if="!isuserlistModalOpen">
                  <div class="row" v-if="userList && userList.length > 0">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="voter-exit-box-outer mb-3" v-for="(user, index) of userList" :key="index">
                        <div class="row">
                          <div class="col-lg-4 col-md-4 col-12">
                            <div class="row">
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">user Name</label>
                                  <div class="from-label-value text-capitalize">
                                    {{ user.ad9 ? user.ad9 : "N/A" }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-12">
                            <div class="row">
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Mobile</label>
                                  <div class="from-label-value text-capitalize">
                                    {{ user.ad15 ? user.ad15 : "N/A" }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-12">
                            <div class="row">
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                  <label class="form-label">Designation</label>
                                  <div class="from-label-value text-capitalize">
                                    {{ user.ad11 ? user.ad11 : "N/A" }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="customer-label-group">
                        <label class="form-label">No records found.</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="isuserlistModalOpen">
                  <div class="custom-modal-spinner-loader">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End view host details modal open -->
  <!-- filter start here -->
  <div class="modal-mask" v-if="guestfiltermodalsflag">
    <div class="modal-md modal-dialog-scrollable modal-fullscreen ms-auto custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Filter By</h5>
          <button type="button" class="btn-close" @click="guestFilterModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Select City<span class="text-danger">*</span></label>
                    <Multiselect v-show="!cityloaderflag" :disabled="adduserregloader" v-model="city"
                      :options="cityList" :searchable="true" label="label" placeholder="Select"
                      class="multiselect-custom" @keypress="isLetterWithSpace($event)" :canClear="true"
                      :closeOnSelect="true" noOptionsText="No Result" :object="true" @select="getAreaByCityId(city)"
                      required />
                    <Multiselect v-show="cityloaderflag" loading :searchable="false" label="label" placeholder="Select"
                      class="multiselect-custom" noOptionsText="No Result" />
                    <div class="custom-error" v-if="v$.city.$error">
                      {{ v$.city.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Select Area<span class="text-danger">*</span></label>
                    <Multiselect v-show="!arealoaderflag" :disabled="adduserregloader" v-model="area"
                      :options="areaList" :searchable="true" label="label" placeholder="Select"
                      class="multiselect-custom" @keypress="isLetterWithSpace($event)" :canClear="true"
                      :closeOnSelect="true" noOptionsText="No Result" :object="true"
                     required />
                    <Multiselect v-show="arealoaderflag" loading :searchable="false" label="label" placeholder="Select"
                      class="multiselect-custom" noOptionsText="No Result" />
                    <div class="custom-error" v-if="v$.area.$error">
                      {{ v$.area.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">GST No.<span class="text-danger">*</span></label>
                      <input type="text" v-model="gstnumber" :disabled="showloader"
                        @input="validateAddGstNumber($event)" class="form-control text-uppercase"
                        placeholder="Enter GST No." maxlength="15" autocomplete="off" />
                      <div class="custom-error" v-if="this.errmsg.erroraddgstnumber">{{
                        this.errmsg.erroraddgstnumber
                      }}</div>
                      <div class="custom-error" v-if="v$.gstnumber.$error">
                        {{ v$.gstnumber.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">PAN No.<span class="text-danger">*</span></label>
                    <input type="text" v-model="pannumber" :disabled="hideaadharinput"
                      class="form-control text-uppercase" placeholder="Enter PAN No."
                      @input="validateAddPanCardNumber($event)" maxlength="10" autocomplete="off" />
                    <div class="custom-error" v-if="this.errmsg.erroraddpannumber">
                      {{ this.errmsg.erroraddpannumber }}
                    </div>
                    <div class="custom-error" v-if="v$.pannumber.$error">
                      {{ v$.pannumber.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- :disabled="(city = '', area = '', pannumber = '', gstnumber = '')
        " -->
        <div class="modal-footer">
          <div class="col-12 m-0">
            <div class="d-flex justify-content-between">
              <button class="btn filter-apply-btn"  @click="
        btnFilterSearch(city, area, pannumber, gstnumber)
        ">
                Apply Filter
              </button>
              <button class="btn btn-link filter-reset-btn" @click="resetGuestFilter">
                Reset All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- filter end here -->
</template>
<script>
import { required, helpers, minLength } from "@vuelidate/validators";
import commonMixin from "../../../mixins/commonMixin.js";
import useValidate from "@vuelidate/core";
import ApiService from "../../../service/ApiService";
// import moment from 'moment';
export default {
  mixins: [commonMixin],
  data() {
    return {
      v$: useValidate(),
      hostList: [],
      loading: false,
      totalRecords: 0,
      isHostDetailsModal: false,
      showLoaderHostDetailsModal: false,
      isShowHostDetails: "",
      hostData: {},
      branchList: [],
      isBranchlistModalOpen: false,
      selectedHost: "",
      userList: [],
      isuserlistModalOpen: false,
      guestfiltermodalsflag: false,
      ac3: "",
      ac7: "",
      page_no: 0,
      errmsg: {},
      errStatus: false,
      client_info: "",
      hoststorageimgpath: "",
      searchmobile: "",
      searchmobloader: false,
      searchfullname: "",
      searchnameloader: false,
      searchmobbtnactive: false,
      searchnamebtnactive: false,
      hidesearchname: false,
      hidesearchmob: false,
      user_info: "",
      city: "",
      area: "",
      cityloaderflag: false,
      arealoaderflag:false,
      adduserregloader: false,
      pannumber: "",
      gstnumber: "",
      stateId: 11,
      cityList: [],
      areaList: [],
    };
  },

  mounted() {
    this.getHostList({ is_verified: 1 });
    
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  validations() {
    return {
      city: { required: helpers.withMessage("Please select city", required) },
      area: { required: helpers.withMessage("Please select area", required) },
      pannumber: {
        required: helpers.withMessage("Please enter PAN number", required),
      },
      gstnumber: {
        minLength: helpers.withMessage("Enter vaild aadhar No.", minLength(12)),
      },
    };
  },
  methods: {
    redirectInactiveHostUrl() {
      this.$router.push("/hosts/unapproved-listing");
    },
    getHostList(e) {
      this.loading = true;
      this.ApiService.getHostList(e).then((data) => {
        if (data.status == 200) {
          this.hostList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.hostList = [];
          this.loading = false;
          this.totalRecords = 0;
        }
        this.searchnameloader = false;
      });
    },
    changePage(event) {
      this.page_no = event.page;
      this.getHostList({ page_no: this.page_no, is_verified: 1  });
    },
    maskedNumber(getvalue) {
      const maskedDigits = "X".repeat(getvalue.length - 6);
      const FirstThreeDigits = getvalue.slice(0, 3);
      const lastThreeDigits = getvalue.slice(-3);
      return FirstThreeDigits + maskedDigits + lastThreeDigits;
    },
    hostdetailModalOpen(e) {
      this.isHostDetailsModal = true;
      this.hostData = e;
    },
    getHostBranchesByHostId() {
      //   console.log(e);
      this.loading = true;
      this.isBranchlistModalOpen = true;
      this.ApiService.getHostBranchesByHostId({ ac1: this.hostData.ac1 }).then(
        (data) => {
          if (data.success == true) {
            this.branchList = data.data;
            // console.log("branchList", this.branchList);
            this.totalRecords = data.count;
            this.loading = false;
          } else {
            this.branchList = null;
            this.totalRecords = 0;
            this.loading = false;
          }
          this.isBranchlistModalOpen = false;
        }
      );
    },
    getUserListByHostId() {
      this.loading = true;
      this.isuserlistModalOpen = true;
      this.ApiService.getUserListByHostId({ ac1: this.hostData.ac1 }).then(
        (data) => {
          if (data.success == true) {
            this.userList = data.data;
            // console.log("userList", this.userList);
            this.totalRecords = data.count;
            this.loading = false;
          } else {
            this.branchList = null;
            this.totalRecords = 0;
            this.loading = false;
          }
          this.isuserlistModalOpen = false;
        }
      );
    },
    mobileSearchFormat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }

      var mobileInput = document.getElementById("searchmobInput");
      if (
        (mobileInput.value == "" || mobileInput.length > 1) &&
        (keyCode == 48 ||
          keyCode == 49 ||
          keyCode == 50 ||
          keyCode == 51 ||
          keyCode == 52)
      ) {
        $event.preventDefault();
      }
    },
    // Search Voter Header Input function start here
    // searchMobGuest(searchmobile) {
    //   this.hidesearchmob = true;
    //   this.searchmobloader = true;
    //   this.ac7 = searchmobile;
    //   this.getHostList({
    //     page_no: this.page_no,
    //     ac7: this.ac7,
    //   });
    //   this.searchmobbtnactive = true;
    // },
    // searchMobGuestStop() {
    //   this.searchmobbtnactive = false;
    //   this.searchmobile = "";
    //   this.getHostList({
    //     page_no: this.page_no,
    //   });
    //   this.searchmobloader = false;
    // },
    searchFnameGuest(searchfullname) {
      this.hidesearchname = false;
      this.searchnameloader = true;
      this.ac3 = searchfullname;
      this.getHostList({
        page_no: this.page_no,
        ac3: this.ac3,
        is_verified: 1
      });
      this.searchnamebtnactive = true;
    },
    searchNameGuestStop() {
      this.searchnamebtnactive = false;
      this.searchfullname = "";
      this.getHostList({
        page_no: this.page_no,
        is_verified: 1 
      });
      this.searchnameloader = false;
      this.hidesearchname = false;
    },
    getCityByStateId(e) {
    // console.log("e",e);
      this.cityloaderflag = true;
      this.city = "";
      this.area = "";
      this.ApiService.getcities({ state_id: e }).then((data) => {
        if (data.success == true) {
          this.cityList = data.data;
          this.cityloaderflag = false;
        } else {
          this.cityloaderflag = false;
          this.cityList = [];
        }
      });
    },
    getAreaByCityId(e) {
      this.arealoaderflag = true;
      this.area = "";
      this.ApiService.getareas({ city_id: e.value }).then((data) => {
        if (data.success == true) {
          this.areaList = data.data;
          this.arealoaderflag = false;
        } else {
          this.arealoaderflag = false;
          this.areaList = [];
        }
      });
    },
    // Search Voter Header Input function end here
    guestFilterModalOpen() {
      this.guestfiltermodalsflag = true;
      // console.log("this.stateId",this.stateId);
      this.getCityByStateId(this.stateId);
    
    },
    guestFilterModalClose() {
      this.guestfiltermodalsflag = false;
    },
    validateAddPanCardNumber(e) {
      var regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
      let add_pan_number = e.target.value.toUpperCase();
      this.erroraddpannumber = add_pan_number;
      if (add_pan_number && !add_pan_number.match(regex)) {
        this.errmsg["erroraddpannumber"] = "Please enter valid pan number";
        this.errStatus = true;
      } else {
        this.errmsg["erroraddpannumber"] = "";
        this.errStatus = false;
      }
    },
    validateAddGstNumber(e) {
      var regexGst = /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
      let add_gst_number = e.target.value.toUpperCase();
      this.erroraddgstnumber = add_gst_number;
      if (add_gst_number && !add_gst_number.match(regexGst)) {
        this.errmsg["erroraddgstnumber"] = "Please enter valid GST number";
        this.errStatus = true;
      } else {
        this.errmsg["erroraddgstnumber"] = "";
        this.errStatus = false;
        this.add.pannumber = add_gst_number.substr(2, 10);
      }
      if (add_gst_number.length == 15) {
        const isGstExist = this.companyList.some((d) => d.al6 == add_gst_number);
        if (isGstExist === true) {
          this.errmsg["erroraddgstnumber"] = "GST no. is already exist.";
          this.errStatus = true;
        }
      }
    },
    btnFilterSearch(
      city, area, pannumber, gstnumber
    ) {
      this.v$.$validate();
      if (!this.v$.$error) {
            this.getHostList({
             ac14:area.value,ac12:city.value,ac5:pannumber, ac4:gstnumber,is_verified: 1 
            });
          }
          this.guestfiltermodalsflag = false; 
      },
    },
    resetGuestFilter() {
      this.city = "";
      this.area = "";
      this.pannumber = "";
      this.gstnumber = "";
      // this.getHostList({ page_no: this.page_no, });
      // this.guestfiltermodalsflag = false;
    },
  
  };

</script>
<style scoped>
.modal-bulk-next-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
  width: 115px;
}

.modal-bulk-next-btn:focus {
  box-shadow: none;
}

.company-detail-box-outer {
  padding: 16px 16px 0;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
  width: 100%;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.customer-label-group {
  margin-bottom: 16px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.voter-exit-box-outer {
  padding: 16px 16px 0;
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
}
</style>